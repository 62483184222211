import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <section className="intro">
      <div className="intro-container">
        <h2>About</h2>
        <p>The <strong>Asian Leather and Kink Alliance</strong> is an antiracist, intersectional, and pro-decrim grassroots collective for folx of Asian descent in the kink, fetish, and BDSM community.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>The <strong>Asian Leather and Kink Alliance</strong> is an antiracist, intersectional, and pro-decrim grassroots collective for folx of Asian descent in the kink, fetish, and BDSM community.</p>
        <p>We work in solidarity with abolitionist organizations to combat all oppression in kink, fetish and BDSM community spaces. ALKA welcomes all genders, all sexualities and graysexualities, and all Asian heritages.</p>
        <p>We fight for sex workers and marginalized members in the kink, fetish, and BDSM community and for kinky and fetishist Asians worldwide. We advocate the destigmatization of kink and decriminalization of all sex work.</p>
        <p>We work towards inclusion, representation, safety, and trauma-aware policies and processes in kink, fetish, and BDSM spaces. We provide safe, intentional spaces to discuss important issues relevant to Asian folx and other people of color in the kink, fetish, and BDSM community.</p>
        <p>The Asian Leather and Fetish Alliance was established in October 2020 by Preston "wexx" (he/him).</p>
        <p>Currently, our executive team consists of:</p>
        <ul>
          <li><a href="https://mirubberxx.com" rel="noreferrer noopener" target="_blank"><strong>Preston "wexx"</strong></a>, Founder and Executive Director (United States)</li>
          <li><strong>Pup Zero</strong>, Executive Director (United States)</li>
          <li><strong>Bo Chi Huang</strong>, Executive Director (Canada)</li>
          <li><strong>knoturaverageboi</strong>, Executive Director (Australia)</li>
        </ul>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default AboutPage
